import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cc-nav-counter-chip',
  standalone: true,
  templateUrl: './nav-counter-chip.component.html',
  styleUrl: './nav-counter-chip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavCounterChipComponent {}

import { CdkScrollable } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, type OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngxs/store';
import { NzResizableModule, type NzResizeEvent } from 'ng-zorro-antd/resizable';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgScrollbar } from 'ngx-scrollbar';

import { ResizeDirective, type ResizeEvent } from '@clover/core/directives/resize.directive';
import { LocalStorageService } from '@clover/core/services/persistance.service';

import { TasksHeaderComponent } from './tasks-header/tasks-header.component';
import { TasksTableComponent } from './tasks-table/tasks-table.component';
import { LoadNextTasks } from '../state/tasks.actions';

export const taskDrawerMinWidth = 280;
export const taskDrawerDefaultWidth = 320;

const listMinWidth = 480;
const taskDrawerWidthStorageKey = 'tasks.taskInfoWidth';

@Component({
  selector: 'cc-tasks-list',
  standalone: true,
  imports: [
    TasksHeaderComponent,
    TasksTableComponent,
    NgScrollbar,
    InfiniteScrollDirective,
    CdkScrollable,
    RouterOutlet,
    ResizeDirective,
    NzResizableModule,
  ],
  templateUrl: './tasks-list.component.html',
  styleUrl: './tasks-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksListComponent implements OnInit {
  protected taskDrawerWidth = taskDrawerDefaultWidth;
  protected taskDrawerMaxWidth: number | undefined;
  protected drawerOverlayMode = false;

  protected readonly taskDrawerMinWidth = taskDrawerMinWidth;
  protected readonly cdr = inject(ChangeDetectorRef);

  private readonly storage = inject(LocalStorageService);
  private readonly store = inject(Store);

  ngOnInit(): void {
    this.taskDrawerWidth = Number(this.storage.get(taskDrawerWidthStorageKey)) || taskDrawerDefaultWidth;
  }

  loadNextTasks(): void {
    this.store.dispatch(new LoadNextTasks());
  }

  resizeTaskDrawer({ width }: NzResizeEvent): void {
    this.taskDrawerWidth = width;
    this.storage.set(taskDrawerWidthStorageKey, width);
  }

  handleContainerResize({ newRect: { width } }: ResizeEvent): void {
    this.drawerOverlayMode = width < taskDrawerMinWidth + listMinWidth;
    this.taskDrawerMaxWidth = this.drawerOverlayMode ? width * 0.8 : width - listMinWidth;
    this.taskDrawerWidth = Math.max(Math.min(this.taskDrawerWidth, this.taskDrawerMaxWidth), taskDrawerMinWidth);
    this.storage.set(taskDrawerWidthStorageKey, this.taskDrawerWidth);
  }

  resetTaskDrawerWidth(): void {
    this.taskDrawerWidth = taskDrawerDefaultWidth;
    this.storage.set(taskDrawerWidthStorageKey, taskDrawerDefaultWidth);
  }
}

import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, type OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive, type Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { debounceTime, distinctUntilKeyChanged, map, Observable } from 'rxjs';

import { TaskTableRowComponent } from '@clover/conversations-v4/tasks/task-table-row/task-table-row.component';
import { FilterableTaskStatus, TaskStatus, TaskType } from '@clover/conversations-v4/tasks/tasks.model';
import type { TasksSortingProperty } from '@clover/conversations-v4/tasks/tasks.service';
import { sortDirectionToPagingOrder } from '@clover/core/helpers/paging';
import { UserService } from '@clover/core/services/user.service';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { ButtonGroupComponent } from '@design/buttons/button-group/button-group.component';
import { LimitNumberPipe } from '@design/chips/nav-counter-chip/limit-number.pipe';
import type { SelectItem } from '@design/forms/select/select-item/select-item';
import { SelectItemComponent } from '@design/forms/select/select-item/select-item.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import type { SortDirection } from '@design/table/table';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';

import { NavCounterChipComponent } from '../../../../stories/chips/nav-counter-chip/nav-counter-chip.component';
import { SelectComponent } from '../../../../stories/forms/select/select.component';
import { TableComponent } from '../../../../stories/table/table.component';
import { LoadTasks } from '../../state/tasks.actions';
import { TasksSelectors } from '../../state/tasks.selectors';
import { defaultTaskStatus, taskStatusRoutesMap, taskStatusToRouteParam } from '../../tasks-list.guard';

const statusSelectItems: SelectItem<FilterableTaskStatus>[] = [
  {
    id: 'all',
    title: 'All tasks',
    payload: FilterableTaskStatus.All,
  },
  {
    id: 'incomplete',
    title: 'Incomplete',
    payload: FilterableTaskStatus.Incomplete,
  },
  {
    id: 'completed',
    title: 'Completed',
    payload: FilterableTaskStatus.Completed,
  },
  {
    id: 'closed',
    title: 'Closed',
    payload: FilterableTaskStatus.Closed,
  },
  {
    id: 'not-started',
    title: 'Not Started',
    payload: FilterableTaskStatus.NotStarted,
  },
  {
    id: 'in-progress',
    title: 'In Progress',
    payload: FilterableTaskStatus.InProgress,
  },
  {
    id: 'not-connected',
    title: 'Not Connected',
    payload: FilterableTaskStatus.NotConnected,
  },
  {
    id: 'waiting-for-approval',
    title: 'Waiting for Approval',
    payload: FilterableTaskStatus.WaitingForApproval,
  },
];

@UntilDestroy()
@Component({
  selector: 'cc-tasks-table',
  standalone: true,
  imports: [
    TableComponent,
    AsyncPipe,
    TaskTableRowComponent,
    TrComponent,
    ThComponent,
    ButtonGroupComponent,
    ButtonComponent,
    SelectComponent,
    SelectItemComponent,
    ReactiveFormsModule,
    RouterLink,
    RouterLinkActive,
    TooltipDirective,
    NavCounterChipComponent,
    LimitNumberPipe,
  ],
  templateUrl: './tasks-table.component.html',
  styleUrl: './tasks-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksTableComponent implements OnInit {
  tasks$ = inject(Store).select(TasksSelectors.tasks);
  tasksCount$ = inject(Store).select(TasksSelectors.tasksCount);
  loadingStatus$ = inject(Store).select(TasksSelectors.tasksLoadingStatus);
  sortingOptions$ = inject(Store).select(TasksSelectors.tasksSortingOptions);

  usersIncompleteTasksCount$ = inject(Store).select(TasksSelectors.usersIncompleteTasksCount);

  protected completionSortingOrder$: Observable<SortDirection>;
  protected nameSortingOrder$: Observable<SortDirection>;
  protected assignedAtSortingOrder$: Observable<SortDirection>;

  protected queryFormControl = new FormControl<string>('');
  protected type$ = inject(Store).select(TasksSelectors.tasksType);
  protected statusSelectFormControl = new FormControl<SelectItem<FilterableTaskStatus>>(undefined);

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly TaskStatus = TaskStatus;
  protected readonly TaskType = TaskType;
  protected readonly FilterableTaskStatus = FilterableTaskStatus;

  protected readonly statusSelectItems = statusSelectItems;

  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly userService = inject(UserService);
  private readonly router = inject(Router);
  private readonly store = inject(Store);

  get canViewReceivedTab(): boolean {
    return this.userService.permissions.Task_ViewAssigned;
  }

  get canViewSentTab(): boolean {
    return this.userService.permissions.Task_Assign;
  }

  ngOnInit(): void {
    this.initQueryFilter();
    this.initStatusFilter();

    this.completionSortingOrder$ = this.sortingOptions$.pipe(
      map((sortingOptions) => (sortingOptions.orderBy === 'completionRate' ? sortingOptions.order : null)),
    );

    this.nameSortingOrder$ = this.sortingOptions$.pipe(
      map((sortingOptions) => (sortingOptions.orderBy === 'name' ? sortingOptions.order : null)),
    );

    this.assignedAtSortingOrder$ = this.sortingOptions$.pipe(
      map((sortingOptions) => (sortingOptions.orderBy === 'createdAt' ? sortingOptions.order : null)),
    );
  }

  changeSort(property: TasksSortingProperty, direction: SortDirection): void {
    this.store.dispatch(
      new LoadTasks({
        sortingOptions: {
          order: sortDirectionToPagingOrder(direction),
          orderBy: property,
        },
      }),
    );
  }

  changeType(type: TaskType): void {
    // When changing the type, we should reset the status filter
    // This is the reason why navigation is not done using [routerLink] directive

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { type: type, status: taskStatusToRouteParam(defaultTaskStatus) },
      queryParamsHandling: 'merge',
    });
  }

  private initQueryFilter(): void {
    this.queryFormControl.setValue(this.activatedRoute.snapshot.queryParams['query'] || '', { emitEvent: false });

    this.queryFormControl.valueChanges.pipe(untilDestroyed(this), debounceTime(300)).subscribe((query) => {
      this.setRouteParams({ query: query || undefined });
    });
  }

  private initStatusFilter(): void {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this), distinctUntilKeyChanged('status'))
      .subscribe(({ status: statusParam }) => {
        const status = taskStatusRoutesMap.get(statusParam);
        const selectItem = statusSelectItems.find((item) => item.payload === status);

        this.statusSelectFormControl.setValue(selectItem, { emitEvent: false });
      });

    this.statusSelectFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((selectItem) => {
      const status = selectItem.payload;
      const statusParam = taskStatusToRouteParam(status);

      this.setRouteParams({ status: statusParam });
    });
  }

  private setRouteParams(params: Params): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }
}

import type { FilterableTaskStatus, TaskType } from '@clover/conversations-v4/tasks/tasks.model';
import type { TasksSortingProperty } from '@clover/conversations-v4/tasks/tasks.service';
import type { SortingOptions } from '@clover/core/helpers/paging';

export class LoadTasks {
  static readonly type = '[Tasks] Load Tasks';

  constructor(
    public payload: {
      query?: string;
      type?: TaskType;
      status?: FilterableTaskStatus;
      sortingOptions?: SortingOptions<TasksSortingProperty>;
    },
  ) {}
}

export class LoadNextTasks {
  static readonly type = '[Tasks] Load Next Tasks';
}

export class LoadUsersIncompleteTasksCount {
  static readonly type = '[Tasks] Load Users Incomplete Tasks Count';
}

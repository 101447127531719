import { inject, Injectable } from '@angular/core';
import { Action, State, type StateContext } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import * as R from 'ramda';
import { catchError, map, of, tap, type Observable } from 'rxjs';

import { TasksService } from '@clover/conversations-v4/tasks/tasks.service';

import { defaultTasksState, type TasksStateModel } from './tasks-state-model';
import { LoadNextTasks, LoadTasks, LoadUsersIncompleteTasksCount } from './tasks.actions';

@State<TasksStateModel>({
  name: 'tasks',
  defaults: defaultTasksState,
})
@Injectable()
export class TasksState {
  private readonly tasksService = inject(TasksService);

  @Action(LoadTasks, { cancelUncompleted: true })
  loadTasks(ctx: StateContext<TasksStateModel>, { payload }: LoadTasks): Observable<void> {
    const state = ctx.getState();
    const query = R.isNil(payload.query) ? state.query : payload.query;
    const type = R.isNil(payload.type) ? state.type : payload.type;
    const status = R.isNil(payload.status) ? state.status : payload.status;
    const sorting = payload.sortingOptions || state.sorting;

    ctx.patchState({ loadingStatus: 'loading' });

    // Reload new received tasks count on every load
    ctx.dispatch(new LoadUsersIncompleteTasksCount());

    return this.tasksService
      .searchTasks(query, type, status, true, {
        offset: 0,
        orderBy: sorting.orderBy,
        order: sorting.order,
        limit: 50,
      })
      .pipe(
        tap((tasks) => {
          ctx.patchState({ tasks, query, type, status, sorting, loadingStatus: 'loaded' });
        }),
        catchError(() => {
          ctx.patchState({ loadingStatus: 'error' });
          return of(undefined);
        }),
        map(() => undefined),
      );
  }

  @Action(LoadNextTasks, { cancelUncompleted: true })
  loadNextTasks(ctx: StateContext<TasksStateModel>): Observable<void> {
    const state = ctx.getState();
    const offset = state.tasks.count;
    const query = state.query;
    const type = state.type;
    const status = state.status;
    const sortingOptions = state.sorting;

    ctx.patchState({ loadingStatus: 'loading-next' });

    return this.tasksService
      .searchTasks(query, type, status, true, {
        offset,
        orderBy: sortingOptions.orderBy,
        order: sortingOptions.order,
        limit: 50,
      })
      .pipe(
        tap((nextTasks) => {
          ctx.setState(
            patch<TasksStateModel>({
              tasks: patch({
                data: append(nextTasks.data),
                count: state.tasks.count + nextTasks.count,
                paging: nextTasks.paging,
              }),
              loadingStatus: 'loaded',
            }),
          );
        }),
        catchError(() => {
          ctx.patchState({ loadingStatus: 'error' });
          return of(undefined);
        }),
        map(() => undefined),
      );
  }

  @Action(LoadUsersIncompleteTasksCount, { cancelUncompleted: true })
  loadUsersIncompleteTasksCount(ctx: StateContext<TasksStateModel>): Observable<void> {
    return this.tasksService.getUsersIncompleteTasksCount().pipe(
      tap((usersIncompleteTasksCount) => {
        ctx.patchState({ usersIncompleteTasksCount });
      }),
      map(() => undefined),
    );
  }
}

/* eslint-disable import/order */
import { inject } from '@angular/core';
import { Router, type Routes } from '@angular/router';
import { provideStates } from '@ngxs/store';

import { taskInfoResolver } from '@clover/conversations-v4/conversation/task-info.resolver';

import { TasksListComponent } from './tasks-list/tasks-list.component';
import { tasksListGuard, TaskTypeRouteParam } from './tasks-list.guard';
import { tasksListResolver } from './tasks-list.resolver';
import { TasksPageComponent } from './tasks-page.component';

import { TasksState } from './state/tasks.state';
// States has to be imported last due to a bug in @ngxs/store
// @see: https://github.com/ngxs/store/issues/1631
// This comment is here to prevent import sorter from reordering the imports

export const TASKS_BASE_URL = 'tasks';
export const tasksPageRoutes: Routes = [
  {
    path: '',
    providers: [provideStates([TasksState])],
    component: TasksPageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
      {
        path: 'list',
        resolve: {
          tasksLoaded: tasksListResolver,
        },
        canActivate: [tasksListGuard],
        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
        component: TasksListComponent,
        children: [
          {
            path: ':taskId',
            loadComponent: () =>
              import('./tasks-list/tasks-list-drawer/tasks-list-drawer.component').then(
                (c) => c.TasksListDrawerComponent,
              ),
            resolve: {
              task: taskInfoResolver,
            },
          },
        ],
      },

      // Redirects for backward compatibility
      {
        path: 'sent',
        component: TasksListComponent, // This will never render, but it's required in the route config
        canActivate: [
          () => {
            return inject(Router).createUrlTree(['/', TASKS_BASE_URL, 'list'], {
              queryParams: { type: TaskTypeRouteParam.Sent },
            });
          },
        ],
      },
      {
        path: 'received',
        component: TasksListComponent, // This will never render, but it's required in the route config
        canActivate: [
          () => {
            return inject(Router).createUrlTree(['/', TASKS_BASE_URL, 'list'], {
              queryParams: { type: TaskTypeRouteParam.Received },
            });
          },
        ],
      },
    ],
  },
];

import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, map, of } from 'rxjs';

import { LoadTasks } from './state/tasks.actions';
import { taskStatusRoutesMap, taskTypeRoutesMap } from './tasks-list.guard';

export const tasksListResolver: ResolveFn<boolean> = (route) => {
  const { query = '', type: typeRoute, status: statusRoute } = route.queryParams;

  const type = taskTypeRoutesMap.get(typeRoute);
  const status = taskStatusRoutesMap.get(statusRoute);

  const store = inject(Store);

  return store.dispatch(new LoadTasks({ query, type, status })).pipe(
    map(() => true),
    catchError(() => of(false)),
  );
};
